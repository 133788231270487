.join{
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    gap: 10rem;
}

.left-j{
    color: white;
    font-size: 3rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
}

.left-j>hr{
    position: absolute;
    width: 10.5rem;
    border: 2px solid var(--orange);
    border-radius: 20%;
    margin: -10px 0;
}

.left-j>div{
    display: flex;
    gap: 1rem;
}

.right-j{
    /* display: flex;
    justify-content: center;
    align-items: flex-end; */
    position: relative;
    top: -100px;
    width: 25%;
}

.email-container{
    display: flex;
    gap: 3rem;
    background-color: gray;
    padding: 1rem 2rem;
}

.email-container>input{
    background-color: transparent;
    border: none;
    outline: none;
    color: var(--lightgrayz);
}

::placeholder{
    color: var(--lightgrayz);
}

.btn-j{
    background-color: var(--orange);
    color: white;
}

@media screen and (max-width: 768px) {
    .Join{
        flex-direction: column;
        gap: 1rem;
        justify-content: center;
    }

    .left-j{
        font-size: xx-large;
        flex-direction: column;
        justify-content: center;

    }
    .left-j>hr{
        margin: -10px 40px;
    }
    .left-j>div{
        display: block;
        text-align: center;
    }

    .right-j{
        width: 100%;
        gap: 0rem;
    }

    .email-container{
        gap: 0rem;
        padding-right: 1rem;
    }

    .btn-j{
        padding-right: 1px;
        width: 10rem;
    }
}