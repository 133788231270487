.Reasons{
    padding: 0 2rem;
    display: flex;
    gap: 2rem;
}

.left-r{
    flex: 1 1;
    display: grid;
    /* Dividing into grid with 3 columns */
    grid-template-columns: repeat(3, 1fr); 
    /* Gaps bw grid columns 1 rem */
    gap: 1rem;
    /* Gaps bw grid rows 1 rem */
    grid-auto-rows: 1fr;
}

.left-r>img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width: 100%;
    /* Image occupies row 1-3 */
    grid-row: 1/3;
    height: 100%;
}

.left-r>:nth-child(2){
    width: 100%;
    height: 100%;
    /* Image occupies column 2-4 */
    grid-column: 2/4;
}

.left-r>:nth-child(3){
    width: 100%;
    grid-column: 2/3; 
    /* Image takes up 2 row in the grid */
    grid-row: 2;
    height: 100%;
}

.left-r>:nth-child(4){
    width: 100%;
    grid-column: 3/4;
    /* Image takes up 2 row in the grid */
    grid-row: 2;
    height: 100%;
}


/* Right side Styling */
/* ============================================================ */
.right-r{
    flex: 1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    font-size: 2rem;
    color: var(--orange);
}

.right-r>div{
    color: white;
    font-size: 5rem;
    font-weight: bold;
}

.details-r{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.details-r>div{
    display: flex;
    font-size: 1.5rem;
    gap: 1rem;

}

.details-r>div>span{
    display: flex;
    padding-top: 1rem;
}

.details-r>div>img{
    width: 4rem;
    height: 4rem;
}

.right-r>span.partners-heading{
    color: var(--gray);
    font-weight: normal;
}

.right-r>div.partners{
    display: flex;
    gap: 1rem;
    color: var(--gray);
    font-weight: normal;
}

.right-r>div.partners>img{
    width: 5rem;
}

@media screen and (max-width: 768px) {
    .Reasons{
        flex-direction: column;
        text-align: center;
    }

    .right-r>span{
        font-size: x-large;
    }
    .right-r>div.reasons-text{
        text-align: center;
        justify-content: center;
        line-height: 1rem;
        padding: 1rem 0rem;
    }
    .right-r>div.reasons-text>span{
        font-size: xx-large;
    }
    .right-r>div.details-r>div>img{
        width: 2rem;
        height: 2rem;
    }
    .right-r>div.details-r>div>span{
        padding-top: 0.2rem;
        font-size: small;
        justify-content: center; 
        text-align: left;
    }
    .right-r>span.partners-heading{
        font-size: x-large;
    }
    .right-r>div.partners{
        justify-content: center;
    }
    .right-r>div.partners>img{
        width: 2rem;
    }
}